import React from "react";
import { Trans } from "@lingui/macro";
import { Button, Stack, Typography, Box } from "@mui/material";
import succesIcon from "../images/icons/message-check-icon.png";
import Modal from "./Modal";

export const successBody = ({ buttonSuccessAction }) => (
  <Box py={2}>
    <Stack spacing={2}>
      <img
        alt="allfunds_logo"
        src={succesIcon}
        width="60"
          />
      <Stack spacing={1}>
        <Typography variant="h6" fontWeight={600} color={(theme) => theme.palette.primary.dark}>
          <Trans>Thank you for your request!</Trans>
        </Typography>
        <Typography variant="body1" color={(theme) => theme.palette.text.light} fontWeight={400}>
          <Trans>
            Your message was successfully sent.
            We will contact you shortly.
          </Trans>
        </Typography>
        <Typography variant="body" color={(theme) => theme.palette.text.light} fontWeight={400}>
          <Trans>Thanks for your interest!</Trans>
        </Typography>
      </Stack>
      <Box>
        <Button
          variant="secondary"
          fullWidth
          onClick={buttonSuccessAction}
              >
          <Typography
            variant="info"
            sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white !important",
                    fontWeight: 600,
                  }}
                >
            <Trans>Close</Trans>
          </Typography>
        </Button>
      </Box>
    </Stack>
  </Box>
);

function ThankRequestModal({ onClose, buttonSuccessAction }) {
  return (
    <Modal open width={450} onClose={onClose} backgroundColor="white">
      {successBody({ buttonSuccessAction })}
    </Modal>
  );
}

export default ThankRequestModal;
